const size = {
  largeLaptop: '1400px',
  laptop: '1280px',
  smallLaptop: '1024px',
  tablet: '768px',
  mobile: '480px',
  smallScreen: '300px',
};

export const device = {
  largeLaptop: `(max-width: ${size.largeLaptop})`,
  laptop: `(max-width: ${size.laptop})`,
  smallLaptop: `(max-width: ${size.smallLaptop})`,
  tablet: `(max-width: ${size.tablet})`,
  mobile: `(max-width: ${size.mobile})`,
  smallScreen: `(max-width: ${size.smallScreen})`,
};
