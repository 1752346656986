/* eslint-disable no-console */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable func-names */
/* eslint-disable no-multi-assign */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { useEffect } from 'react';
import { isMobileOnly } from 'react-device-detect';

export default function FrontChatMobileScriptInjector() {
  useEffect(() => {
    if (isMobileOnly) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.innerHTML = `
        (function (d, s, id) {
          const fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) return;
          const params = 'uhash=RklQaFVsNnNRZ1ZhWlpvQ0hPdDYyQT09';
          const js = d.createElement(s);
          js.id = id;
          js.src = 'https://cdn.justcall.io/app/assets/js/justcall.js?' + params;
          js.type = 'text/javascript';
          js.async = 'true';
          js.onload = js.onreadystatechange = function () {
            const rs = this.readyState;
            if (rs && rs !== 'complete' && rs !== 'loaded') return;
            try {
              justcall.init();
            } catch (e) {
              console.log('messed up');
            }
          };
          fjs.parentNode.insertBefore(js, fjs);
        })(document, 'script', 'justcall-ajs');
      `;
      document.getElementsByTagName('head')[0].appendChild(script);
    }
  }, []);

  return null;
}
