export const media = {
  tooltipIcon: '/static/icons/tooltip-icon.svg',
  editIcon: '/static/icons/edit-icon.svg',
  profileIcon: '/static/icons/profile-icon.svg',
  homeIcon: '/static/icons/home-icon.svg',
  countyIcon: '/static/icons/county-icon.svg',
  phoneIcon: '/static/icons/phone-icon.svg',
  emailicon: '/static/icons/email-icon.svg',
  beds: '/static/icons/sell/bed.svg',
  baths: '/static/icons/sell/bath.svg',
  houseArea: '/static/icons/sell/house-area.svg',
  propertyType: '/static/icons/sell/house-type.svg',
  arrowDown: '/static/icons/new-arrow-down.svg',
  arrowUp: '/static/icons/arrow-up.svg',
};

export const listingStatuses = {
  initialized: 'initialized',
  adminReview: 'admin_review',
  sellerAnswering: 'seller_answering',
  sellerReviewing: 'seller_reviewing',
  completed: 'completed',
};

export const ADMIN = 'admin';

export const SELLER = 'seller';

export const questionTypes = {
  propertyImages: 'property-images',
  photoId: 'photo-id-images',
};
