import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { isEmpty } from 'lodash';

import { api } from 'lib/axios';
import { getUTMParams } from 'utils/helpers';
import { ZohoData } from './types';

const zohoHomeValuationThunk = createAsyncThunk(
  'zohoHomeValuation',
  async (data: ZohoData, { rejectWithValue }) => {
    try {
      const utmParams = getUTMParams();

      if (!isEmpty(utmParams)) {
        utmParams.utm_string = new URLSearchParams(utmParams).toString();
      }

      const response = await api.post('/leads', {
        ...data,
        ...utmParams,
        referrer: document?.referrer,
      });

      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return rejectWithValue(error.response?.data);
      }

      return rejectWithValue(error);
    }
  }
);

export { zohoHomeValuationThunk };
