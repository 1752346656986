import axios from 'axios';
import Cookies from 'js-cookie';

export const api = axios.create({
  baseURL: `https://${process.env.NEXT_PUBLIC_BACKEND_URL}/api`,
  headers: {
    'Content-Type': 'application/json',
    Authorization: Cookies.get('jwt:richr') || '',
  },
});
