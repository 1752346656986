import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { isEmpty, isNil, map } from 'lodash';

import { api } from 'lib/axios';
import { LISTING_ID_KEY } from '../constants';
import {
  CreateListingData,
  ListingData,
  ListingTokenPayload,
  UpdateListingPayload,
} from './types';
// eslint-disable-next-line import/no-cycle
import { updateListingImageUploadProgress } from '.';
import { getUTMParams } from 'utils/helpers';

const createListingThunk = createAsyncThunk(
  'createListing',
  async (data: CreateListingData, { rejectWithValue }) => {
    try {
      const utmParams = getUTMParams();

      if (!isEmpty(utmParams)) {
        utmParams.utm_string = new URLSearchParams(utmParams).toString();
      }

      const response = await api.post('/listings/register', {
        ...data,
        ...utmParams,
        referrer: document?.referrer,
      });
      const listingId = response.data?.data?.id;

      if (!isNil(listingId)) {
        localStorage.setItem(LISTING_ID_KEY, listingId);
      }

      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return rejectWithValue(error.response?.data);
      }

      return rejectWithValue(error);
    }
  }
);

const createAdminListingThunk = createAsyncThunk(
  'createAdminListing',
  async (
    { data, token }: { data: CreateListingData; token: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.post('admin/listings/register', data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return rejectWithValue(error.response?.data);
      }

      return rejectWithValue(error);
    }
  }
);

function extractImagesIds(images: any) {
  return map(images, (imageData) =>
    typeof imageData === 'string' ? imageData : imageData.id
  );
}

const updateListingThunk = createAsyncThunk(
  'updateListing',
  async (
    {
      data,
      listingId,
    }: {
      data: UpdateListingPayload;
      listingId: string;
    },
    { rejectWithValue, getState }
  ) => {
    const {
      listing: { data: listingData },
    } = getState() as { listing: { data: ListingData } };

    const updatedListingData = {
      ...listingData,
      images: extractImagesIds(listingData.images),
      photo_id_images: extractImagesIds(listingData.photo_id_images),
    };

    try {
      const response = await api.put(`/listings/${listingId}`, {
        ...updatedListingData,
        ...data,
      });

      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return rejectWithValue(error.response?.data);
      }

      return rejectWithValue(error);
    }
  }
);

const loadListingDataThunk = createAsyncThunk(
  'loadListing',
  async (listingId: string, { rejectWithValue }) => {
    try {
      const response = await api.get(`/listings/${listingId}`);

      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 404) {
          localStorage.removeItem(LISTING_ID_KEY);
        }

        return rejectWithValue(error.response?.data);
      }

      return rejectWithValue(error);
    }
  }
);

const deleteListingThunk = createAsyncThunk(
  'deleteListing',
  async (listingId: string, { rejectWithValue }) => {
    try {
      const response = await api.delete(`/listings/${listingId}`);

      if (response.status === 204) {
        localStorage.removeItem(LISTING_ID_KEY);
        return true;
      }

      throw new Error('Unable to delete listing!');
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return rejectWithValue(error.response?.data);
      }

      return rejectWithValue(error);
    }
  }
);

const verifyListingTokenThunk = createAsyncThunk(
  'verifyListingToken',
  async (data: ListingTokenPayload, { rejectWithValue }) => {
    try {
      const response = await api.post('/listings/verify-token', data);

      const listingId = response.data?.id;

      if (!isNil(listingId)) {
        localStorage.setItem(LISTING_ID_KEY, listingId);
      }

      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return rejectWithValue(error.response?.data);
      }

      return rejectWithValue(error);
    }
  }
);

const uploadListingImageThunk = createAsyncThunk(
  'uploadListingImage',
  async (
    {
      id,
      file,
      listingID,
    }: { id: string; file: File; listingID: string; isPhotoId?: boolean },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const formData = new FormData();

      formData.append('image', file);

      const response = await api.post(
        `/listings/${listingID}/upload-image`,
        formData,
        {
          onUploadProgress: (progressEvent) => {
            const progress = (progressEvent.loaded / progressEvent.total) * 100;

            dispatch(updateListingImageUploadProgress({ id, progress }));
          },
        }
      );

      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return rejectWithValue(error.response?.data);
      }

      return rejectWithValue(error);
    }
  }
);

export {
  createListingThunk,
  createAdminListingThunk,
  updateListingThunk,
  loadListingDataThunk,
  deleteListingThunk,
  verifyListingTokenThunk,
  uploadListingImageThunk,
};
