/* eslint-disable no-param-reassign */
import { createSlice, current } from '@reduxjs/toolkit';
import { filter, get, isEqual } from 'lodash';

import { asyncFlowInitialState } from 'store/constants';
// eslint-disable-next-line import/no-cycle
import {
  createListingThunk,
  createAdminListingThunk,
  deleteListingThunk,
  loadListingDataThunk,
  updateListingThunk,
  uploadListingImageThunk,
  verifyListingTokenThunk,
} from './thunks';
import {
  ListingData,
  ListingFileData,
  UploadImageListingData,
  ListingImageData,
} from './types';
import {
  requestPropertyAnswersFromSeller,
  requestUpdateListingStatus,
} from 'containers/county-form/slice/thunks';
import { listingStatuses } from 'containers/county-form/constants';

const initialState = {
  ...asyncFlowInitialState,
  createListing: asyncFlowInitialState,
  createAdminListing: asyncFlowInitialState,
  deleteListing: asyncFlowInitialState,
  updateListing: asyncFlowInitialState,
  verifyListingToken: asyncFlowInitialState,
  uploadListingImages: {} as UploadImageListingData,
};

function updateListingImageStatus(
  id: string,
  newStatus: string,
  currentList: ListingFileData[],
  listDraft: ListingFileData[]
) {
  if (get(get(currentList, id), 'status') === 'pending') {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    listDraft[id].status = newStatus;
  }
}

export const listingSlice = createSlice({
  name: 'listing',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(createListingThunk.pending, ({ createListing }) => {
      createListing.loading = true;
      createListing.error = null;
    });
    builder.addCase(createListingThunk.fulfilled, (state, action) => {
      state.createListing.loading = false;
      state.createListing.data = action.payload;
      state.createListing.error = false;
      state.data = action.payload.data;
    });
    builder.addCase(
      createListingThunk.rejected,
      ({ createListing }, action) => {
        createListing.loading = false;
        createListing.data = false;
        createListing.error = action.payload;
      }
    );

    // createAdminListing
    builder
      .addCase(createAdminListingThunk.pending, ({ createAdminListing }) => {
        createAdminListing.loading = true;
        createAdminListing.error = null;
      })
      .addCase(createAdminListingThunk.fulfilled, (state, action) => {
        state.createAdminListing.loading = false;
        state.createAdminListing.data = action.payload;
        state.createAdminListing.error = false;
        state.data = action.payload.data;
      })
      .addCase(
        createAdminListingThunk.rejected,
        ({ createAdminListing }, action) => {
          createAdminListing.loading = false;
          createAdminListing.data = false;
          createAdminListing.error = action.payload;
        }
      );

    builder.addCase(loadListingDataThunk.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(loadListingDataThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    });
    builder.addCase(loadListingDataThunk.rejected, (state, action) => {
      state.loading = false;
      state.data = false;
      state.error = action.payload;
    });
    builder.addCase(deleteListingThunk.pending, ({ deleteListing }) => {
      deleteListing.loading = true;
      deleteListing.error = null;
    });
    builder.addCase(deleteListingThunk.fulfilled, (state, action) => {
      const { seller } = current(state.data) as ListingData;

      state.deleteListing.loading = false;
      state.deleteListing.data = action.payload;
      state.deleteListing.error = null;
      state.createListing.data = null;
      state.data = { seller };
    });
    builder.addCase(
      deleteListingThunk.rejected,
      ({ deleteListing }, action) => {
        deleteListing.loading = false;
        deleteListing.data = false;
        deleteListing.error = action.payload;
      }
    );
    builder.addCase(updateListingThunk.pending, ({ updateListing }) => {
      updateListing.loading = true;
      updateListing.error = null;
    });
    builder.addCase(updateListingThunk.fulfilled, (state, action) => {
      state.updateListing.loading = false;
      state.updateListing.data = action.payload;
      state.updateListing.error = false;

      state.data = action.payload;
    });
    builder.addCase(
      updateListingThunk.rejected,
      ({ updateListing }, action) => {
        updateListing.loading = false;
        updateListing.data = false;
        updateListing.error = action.payload;
      }
    );
    builder.addCase(
      verifyListingTokenThunk.pending,
      ({ verifyListingToken }) => {
        verifyListingToken.loading = true;
        verifyListingToken.error = null;
      }
    );
    builder.addCase(verifyListingTokenThunk.fulfilled, (state, action) => {
      state.verifyListingToken.loading = false;
      state.verifyListingToken.data = 'Token Verified';
      state.verifyListingToken.error = false;
      state.data = action.payload;
    });
    builder.addCase(
      verifyListingTokenThunk.rejected,
      ({ verifyListingToken }, action) => {
        verifyListingToken.loading = false;
        verifyListingToken.data = false;
        verifyListingToken.error = action.payload;
      }
    );
    builder.addCase(requestPropertyAnswersFromSeller.fulfilled, ({ data }) => {
      (data as ListingData).status = listingStatuses.sellerAnswering;
    });
    builder.addCase(
      requestUpdateListingStatus.fulfilled,
      ({ data }, action) => {
        const { updatedStatus } = action.meta.arg;

        if (isEqual(updatedStatus, 'completed')) {
          (data as ListingData).status = listingStatuses.completed;
          return;
        }

        (data as ListingData).status = isEqual(updatedStatus, 'answered')
          ? listingStatuses.sellerReviewing
          : listingStatuses.adminReview;
      }
    );
    builder
      .addCase(uploadListingImageThunk.pending, (state, action) => {
        const { id, file, isPhotoId } = action.meta.arg;

        state.uploadListingImages = {
          ...current(state.uploadListingImages),
          [id]: {
            id,
            fileName: file.name,
            progress: 0,
            status: 'pending',
            isPhotoId,
          },
        };
      })
      .addCase(uploadListingImageThunk.fulfilled, (state, action) => {
        const { id, isPhotoId } = action.meta.arg;
        const listing = state.data as ListingData;

        updateListingImageStatus(
          id,
          'fulfilled',
          current(state.uploadListingImages),
          state.uploadListingImages
        );

        if (isPhotoId) {
          listing.photo_id_images = [
            ...current(listing.photo_id_images),
            action.payload.id,
          ];
        } else {
          listing.images = [...current(listing.images), action.payload.id];
        }
      })
      .addCase(
        uploadListingImageThunk.rejected,
        ({ uploadListingImages }, action) => {
          updateListingImageStatus(
            action.meta.arg.id,
            'rejected',
            current(uploadListingImages),
            uploadListingImages
          );
        }
      );
  },
  reducers: {
    resetListingState(state) {
      state.data = null;
      state.error = null;
    },
    resetCreateOrUpdateListingState({ updateListing, createListing }) {
      createListing.data = null;
      createListing.error = null;
      updateListing.data = null;
      updateListing.error = null;
    },
    resetVerifyListingTokenState({ verifyListingToken }) {
      verifyListingToken.data = null;
      verifyListingToken.error = null;
    },
    updateListingImageUploadProgress({ uploadListingImages }, action) {
      const { id, progress } = action.payload;
      uploadListingImages[id].progress = progress;
    },
    removeListingImage(state, { payload }) {
      const { id, isSaved, isPhotoId } = payload;
      const listing = state.data as ListingData;

      if (isPhotoId) {
        listing.photo_id_images = filter(
          current(listing.photo_id_images),
          (imageData) =>
            isSaved
              ? !isEqual(id, (imageData as ListingImageData).id)
              : !isEqual(id, imageData)
        );
      } else {
        listing.images = filter(current(listing.images), (imageData) =>
          isSaved
            ? !isEqual(id, (imageData as ListingImageData).id)
            : !isEqual(id, imageData)
        );
      }
    },
  },
});

export const {
  resetListingState,
  resetCreateOrUpdateListingState,
  resetVerifyListingTokenState,
  updateListingImageUploadProgress,
  removeListingImage,
} = listingSlice.actions;
export default listingSlice.reducer;
