import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IListing, INewUser, ISteps, OnboardingState } from './types';

const initialState: OnboardingState = {
  step: 'stepUser',
  newUser: undefined,
  listing: undefined,
};

/* eslint-disable no-param-reassign */
export const onboardingSlice = createSlice({
  name: 'onboardingSlice',
  initialState,
  reducers: {
    changeStep(state, { payload }: PayloadAction<ISteps>) {
      state.step = payload;
    },
    fillNewUser(state, { payload }: PayloadAction<INewUser>) {
      state.newUser = payload;
    },
    resetOnboarding(state) {
      state.step = 'stepUser';
      state.newUser = undefined;
      state.listing = undefined;
    },
    fillListing(state, { payload }: PayloadAction<IListing>) {
      state.listing = payload;
    },
  },
});

export const { changeStep, fillNewUser, resetOnboarding, fillListing } =
  onboardingSlice.actions;
