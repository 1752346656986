import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { api } from 'lib/axios';
import { CMARequest } from './types';

const cmaThunk = createAsyncThunk(
  'cmaReport',
  async (data: CMARequest, { rejectWithValue }) => {
    try {
      const { leadId, ...rest } = data;

      const response = await api.post(`/leads/${leadId}/request-cma`, {
        ...rest,
      });

      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return rejectWithValue(error.response?.data);
      }

      return rejectWithValue(error);
    }
  }
);

export { cmaThunk };
