import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';

import { listingSlice } from 'containers/listing/slice';
import { freeHomeValuationSlice } from 'containers/free-home-valuation/slice';
import { authSlice } from './slices/auth';
import { globalSlice } from './slices/global';
import { onboardingSlice } from './slices/onboarding';
import { homesSlice } from './slices/homes';
import { homeValuationSlice } from './slices/homeValuation';
import { cmaSlice } from './slices/cma';
import { propertyQuestionnaireSlice } from 'containers/county-form/slice';

const authPersist = {
  key: 'auth',
  storage,
};

const authPersistedReducer = persistReducer(authPersist, authSlice.reducer);

export const store = configureStore({
  reducer: {
    [authSlice.name]: authPersistedReducer,
    [globalSlice.name]: globalSlice.reducer,
    [onboardingSlice.name]: onboardingSlice.reducer,
    [homesSlice.name]: homesSlice.reducer,
    [homeValuationSlice.name]: homeValuationSlice.reducer,
    [listingSlice.name]: listingSlice.reducer,
    [cmaSlice.name]: cmaSlice.reducer,
    [freeHomeValuationSlice.name]: freeHomeValuationSlice.reducer,
    [propertyQuestionnaireSlice.name]: propertyQuestionnaireSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export interface IAsyncFlowInitialState {
  loading: boolean;
  data: unknown;
  error: unknown;
}
