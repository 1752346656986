/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { cmaThunk } from './thunks';
import { IAsyncFlowInitialState } from './types';

const asyncFlowInitialState: IAsyncFlowInitialState = {
  loading: false,
  data: null,
  error: null,
};

const initialState = {
  cma: asyncFlowInitialState,
};

export const cmaSlice = createSlice({
  name: 'cma',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(cmaThunk.pending, ({ cma }) => {
      cma.loading = true;
      cma.error = null;
    });
    builder.addCase(cmaThunk.fulfilled, ({ cma }, action) => {
      cma.loading = false;
      cma.data = action.payload;
      cma.error = null;
    });
    builder.addCase(cmaThunk.rejected, ({ cma }, action) => {
      cma.loading = false;
      cma.data = null;
      cma.error = action.payload;
    });
  },
  reducers: {},
});

export default cmaSlice.reducer;
