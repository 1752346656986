import type { AppProps } from 'next/app';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import Script from 'next/script';
import { useEffect, useState } from 'react';
import 'swiper/css';
import 'styles/fonts.css';
import { GoogleOAuthProvider } from '@react-oauth/google';
import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import TagManager from 'react-gtm-module';
import { useRouter } from 'next/router';
import { IntlProvider } from 'react-intl';
import { isEmpty, isEqual } from 'lodash';
import 'tippy.js/dist/tippy.css';

import { GlobalStyles } from 'styles/globalStyles';
import { persistor, store } from 'store';
import { queryClientConfigs } from 'lib/react-query';
import { defaultTheme } from 'styles/theme';
import RouteLoader from 'components/common/route-loader';
import FrontChatMobileScriptInjector from 'components/frontchat-mobile-script-injector';
import { getUTMParams } from 'utils/helpers';
import {
  LISTING_PAGE_ROUTE,
  LISTING_CONTINUE_PAGE_ROUTE,
  HOME_VALUATION_ROUTE,
} from 'constants/routes';

export default function MyApp({ Component, pageProps }: AppProps) {
  const [queryClient] = useState(() => new QueryClient(queryClientConfigs));
  const [googleApiLoaded, setGoogleApiLoaded] = useState(false);
  const [utmParamsState, setUtmParamsState] = useState({});
  const { pathname, query, push: pushRoute, ...router } = useRouter();
  const [state, setState] = useState({
    isRouteChanging: false,
    loadingKey: 0,
  });

  useEffect(() => {
    const currentUtmParams = getUTMParams(query);

    // Update UTM params if param state and url are different.
    if (!isEqual(utmParamsState, currentUtmParams)) {
      const shouldResetUtmParams =
        (window.sessionStorage.getItem('resetUtmParams') === 'true' &&
          !isEqual(pathname, HOME_VALUATION_ROUTE)) ||
        (pathname.includes(LISTING_PAGE_ROUTE) &&
          !isEqual(pathname, LISTING_PAGE_ROUTE) &&
          !isEqual(pathname, LISTING_CONTINUE_PAGE_ROUTE));

      // Save UTM params in URL in local state.
      if (isEmpty(utmParamsState) && !isEmpty(currentUtmParams)) {
        setUtmParamsState(currentUtmParams);
      }
      // check if user is on the screens after sign-up on listing page.
      else if (shouldResetUtmParams) {
        setUtmParamsState({});
        window.sessionStorage.removeItem('resetUtmParams');
      }
      // Update URL with saved params without refreshing the page.
      else {
        pushRoute(
          {
            pathname,
            query: { ...query, ...utmParamsState },
          },
          undefined,
          { shallow: true }
        );
      }
    }
  }, [utmParamsState, pathname, pushRoute, query]);

  useEffect(() => {
    const handleRouteChangeStart = () => {
      setState((prevState) => ({
        ...prevState,
        isRouteChanging: true,
        // TODO: fix this bitwise XOR operator.
        // eslint-disable-next-line no-bitwise
        loadingKey: prevState.loadingKey ^ 1,
      }));
    };

    const handleRouteChangeEnd = () => {
      setState((prevState) => ({
        ...prevState,
        isRouteChanging: false,
      }));
    };

    router.events.on('routeChangeStart', handleRouteChangeStart);
    router.events.on('routeChangeComplete', handleRouteChangeEnd);
    router.events.on('routeChangeError', handleRouteChangeEnd);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
      router.events.off('routeChangeComplete', handleRouteChangeEnd);
      router.events.off('routeChangeError', handleRouteChangeEnd);
    };
  }, [router.events]);

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_GTM_ID) {
      TagManager.initialize({ gtmId: process.env.NEXT_PUBLIC_GTM_ID });
    }
  }, []);

  function onLoad() {
    setGoogleApiLoaded(true);
  }

  return (
    <>
      <RouteLoader
        isRouteChanging={state.isRouteChanging}
        key={state.loadingKey}
      />

      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <QueryClientProvider client={queryClient}>
            <Hydrate state={pageProps.dehydratedState}>
              {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
              <GoogleOAuthProvider clientId={process.env.GOOGLE_CLIENT_ID!}>
                <ThemeProvider theme={defaultTheme}>
                  <IntlProvider locale="en" defaultLocale="en">
                    <Script
                      src={`https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_PLACES_AUTOCOMPLETE}&libraries=places`}
                      onLoad={onLoad}
                      strategy="lazyOnload"
                    />
                    <GlobalStyles />
                    {googleApiLoaded && <Component {...pageProps} />}
                  </IntlProvider>
                </ThemeProvider>
              </GoogleOAuthProvider>
            </Hydrate>
          </QueryClientProvider>
        </PersistGate>
      </Provider>
      <FrontChatMobileScriptInjector />
    </>
  );
}
