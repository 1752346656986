export type ThemeType = typeof defaultTheme;

export const defaultTheme = {
  background: '#fff',
  menuBackground: '#fff',
  header: {
    height: '96px',
  },

  // ************* Fonts ************* //
  typography: {
    family: 'Walsheim, sans-serif',
    fontFamily: {
      walsheimProLight: 'WalsheimPro-Light',
      walsheimProRegular: 'WalsheimPro-Regular',
      walsheimProMedium: 'WalsheimPro-Medium',
      walsheimProBold: 'WalsheimPro-Bold',

      Inter: 'Inter',
    },
    fontWeights: {
      light: 300,
      regular: 400,
      medium: 500,
      semibold: 600,
      bold: 700,
    },
    titles: {
      default: {
        fontSize: '48px',
        lineHeight: '55px',
      },
      mode1: {
        fontSize: '32px',
        lineHeight: '40px',
      },
      mode2: {
        fontSize: '50px',
        lineHeight: '84px',
      },
      mode3: {
        fontSize: '36px',
        lineHeight: '42px',
      },
      mode4: {
        fontSize: '60px',
        lineHeight: '70px',
      },
      mode5: {
        fontSize: '56px',
        lineHeight: '64px',
      },
      mode6: {
        fontSize: '46px',
        lineHeight: '64px',
      },
      mode7: {
        fontSize: '32px',
        lineHeight: '48px',
      },
    },
    texts: {
      default: {
        fontSize: '16px',
        lineHeight: '24px',
      },
      mode1: {
        fontSize: '16px',
        lineHeight: '18px',
      },
      mode2: {
        fontSize: '12px',
        lineHeight: '20px',
      },
      mode3: {
        fontSize: '22px',
        lineHeight: '30px',
      },
      mode4: {
        fontSize: '18px',
        lineHeight: '20px',
      },
      mode5: {
        fontSize: '24px',
        lineHeight: '28px',
      },
      mode6: {
        fontSize: '24px',
        lineHeight: '36px',
      },
      mode7: {
        fontSize: '20px',
        lineHeight: '24px',
      },
      mode8: {
        fontSize: '18px',
        lineHeight: '36px',
      },
      mode9: {
        fontSize: '20px',
        lineHeight: '30px',
      },
      mode10: {
        fontSize: '24px',
        lineHeight: '32px',
      },
      mode11: {
        fontSize: '28px',
        lineHeight: '42px',
      },
    },
  },

  // ************* Colors ************* //
  colors: {
    white: '#ffffff',
    whitePallet1: '#f6f5f5',
    whitePallet2: '#fcfcfc',
    whitePallet3: '#f9fbfc',
    whitePallet4: '#f0f6f4',
    whitePallet5: '#FFF2F1',

    black: '#000000',
    blackPallet1: '#212529',
    blackPallet2: '#00152a',
    blackPallet3: '#151D25',
    blackPallet4: '#003d2e',
    blackPallet5: '#323941',
    blackPallet6: '#1b2126',
    blackPallet7: '#272E36',

    gray: '#595a5b',
    grayPallet1: '#2b2b2b',
    grayPallet2: '#300000',
    grayPallet3: '#d8d8d8',
    grayPallet4: '#fafafa',
    grayPallet5: '#e8e8e8',
    grayPallet6: '#b1b1b1',
    grayPallet7: '#5c5c5c',
    grayPallet8: '#bdbdbd',
    grayPallet9: '#7F8589',
    grayPallet10: '#555E67',
    grayPallet11: '#636d73',
    grayPallet12: '#a3bac6',
    grayPallet13: '#8f9090',
    grayPallet14: '#202020',
    grayPallet15: '#8d9090',
    grayPallet16: '#393e45',
    grayPallet17: '#84868b',
    grayPallet18: '#d7dbde',
    grayPallet19: '#e4e8eb',
    grayPallet20: '#F3F5F6',
    grayPallet21: '#B9B9B9',
    grayPallet22: '#BFC0C0',
    grayPallet23: '#E1E1E1',
    grayPallet24: '#B2B6B8',
    grayPallet25: '#A7ABAD',
    grayPallet26: '#B2B7BA',
    grayPallet27: '#C3C8CB',
    grayPallet28: '#BEC3C7',
    grayPallet29: '#D7DFDD',
    grayPallet30: '#AAB0B4',

    blue: '#0082bc',
    bluePallet1: '#00152a',
    bluePallet2: '#2684ff',
    bluePallet3: '#00202f',
    bluePallet4: '#f1f9fd',
    bluePallet5: '#f1f7fb',
    bluePallet6: '#17B8F4',
    bluePallet7: '#71899F',
    bluePallet8: '#CED7E0',
    bluePallet9: '#e5f0ee',
    bluePallet10: '#328eb5',
    bluePallet11: '#0097da',

    red: '#FF0000',
    redPallet1: '#f45055',
    redPallet2: '#f5afb1',
    redPallet3: '#ea604e',
    redPallet4: '#ff7866',

    green: '#00d4a1',
    greenPallet1: '#edfcf8',
    greenPallet2: '#1DEFBC',
    greenPallet3: '#00b98c',
    greenPallet4: '#3f434a',
    greenPallet5: '#00C595',
    greenPallet6: '#0FE9B4',
    greenPallet7: '#184639',
    greenPallet8: '#34836D',

    yellow: '#FFD25D',
    yellowPallet1: '#ffd15d',
    yellowPallet2: '#fff9eb',
    yellowPallet3: '#FDC83E',
    yellowPallet4: '#f8fbf9',

    linearGradient1: `linear-gradient(
      90deg,
      #fff 0%,
      #fff 19.27%,
      rgba(255, 255, 255, 0.91) 34.9%,
      rgba(255, 255, 255, 0.75) 51.04%,
      rgba(255, 255, 255, 0.5) 67.19%,
      rgba(255, 255, 255, 0) 100%
    )`,
  },
  media: {
    header: {
      height: '60px',
    },
    typography: {
      titles: {
        default: {
          fontSize: '24px',
          lineHeight: '32px',
        },
      },
    },
  },
};
