/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import {
  requestPropertyQuestionnaire,
  requestMarkedQuestions,
  requestPropertyAnswersFromSeller,
  requestUpdateListingStatus,
  requestSendEmailReminder,
} from './thunks';
import { IAsyncFlowInitialState } from './types';

const asyncFlowInitialState: IAsyncFlowInitialState = {
  loading: false,
  data: null,
  error: null,
};

const initialState = {
  ...asyncFlowInitialState,
  markedQuestions: asyncFlowInitialState,
  requestAnswers: asyncFlowInitialState,
  updateListingStatus: asyncFlowInitialState,
  sendEmailReminder: asyncFlowInitialState,
};

export const propertyQuestionnaireSlice = createSlice({
  name: 'propertyQuestionnaire',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(
      requestPropertyQuestionnaire.pending,
      (propertyQuestionnaire, action) => {
        const { refetch } = action.meta.arg;

        if (!refetch) {
          propertyQuestionnaire.loading = true;
        }

        propertyQuestionnaire.error = null;
      }
    );
    builder.addCase(
      requestPropertyQuestionnaire.fulfilled,
      (propertyQuestionnaire, action) => {
        propertyQuestionnaire.loading = false;
        propertyQuestionnaire.data = action.payload;
        propertyQuestionnaire.error = null;
      }
    );
    builder.addCase(
      requestPropertyQuestionnaire.rejected,
      (propertyQuestionnaire, action) => {
        propertyQuestionnaire.loading = false;
        propertyQuestionnaire.data = null;
        propertyQuestionnaire.error = action.payload;
      }
    );
    builder.addCase(
      requestMarkedQuestions.pending,
      ({ markedQuestions }, action) => {
        const { refetch } = action.meta.arg;

        if (!refetch) {
          markedQuestions.loading = true;
        }

        markedQuestions.error = null;
      }
    );
    builder.addCase(
      requestMarkedQuestions.fulfilled,
      ({ markedQuestions }, action) => {
        markedQuestions.loading = false;
        markedQuestions.data = action.payload;
        markedQuestions.error = null;
      }
    );
    builder.addCase(
      requestMarkedQuestions.rejected,
      ({ markedQuestions }, action) => {
        markedQuestions.loading = false;
        markedQuestions.data = null;
        markedQuestions.error = action.payload;
      }
    );
    builder.addCase(
      requestPropertyAnswersFromSeller.pending,
      ({ requestAnswers }) => {
        requestAnswers.loading = true;
        requestAnswers.error = null;
      }
    );
    builder.addCase(
      requestPropertyAnswersFromSeller.fulfilled,
      ({ requestAnswers }, action) => {
        requestAnswers.loading = false;
        requestAnswers.data = action.payload;
        requestAnswers.error = null;
      }
    );
    builder.addCase(
      requestPropertyAnswersFromSeller.rejected,
      ({ requestAnswers }, action) => {
        requestAnswers.loading = false;
        requestAnswers.data = null;
        requestAnswers.error = action.payload;
      }
    );
    builder.addCase(
      requestUpdateListingStatus.pending,
      ({ updateListingStatus }) => {
        updateListingStatus.loading = true;
        updateListingStatus.error = null;
      }
    );
    builder.addCase(
      requestUpdateListingStatus.fulfilled,
      ({ updateListingStatus }, action) => {
        updateListingStatus.loading = false;
        updateListingStatus.data = action.payload;
        updateListingStatus.error = null;
      }
    );
    builder.addCase(
      requestUpdateListingStatus.rejected,
      ({ updateListingStatus }, action) => {
        updateListingStatus.loading = false;
        updateListingStatus.data = null;
        updateListingStatus.error = action.payload;
      }
    );
    builder.addCase(
      requestSendEmailReminder.pending,
      ({ sendEmailReminder }) => {
        sendEmailReminder.loading = true;
        sendEmailReminder.error = null;
      }
    );
    builder.addCase(
      requestSendEmailReminder.fulfilled,
      ({ sendEmailReminder }, action) => {
        sendEmailReminder.loading = false;
        sendEmailReminder.data = action.payload;
        sendEmailReminder.error = null;
      }
    );
    builder.addCase(
      requestSendEmailReminder.rejected,
      ({ sendEmailReminder }, action) => {
        sendEmailReminder.loading = false;
        sendEmailReminder.data = null;
        sendEmailReminder.error = action.payload;
      }
    );
  },
  reducers: {
    resetQuestions(state) {
      state.data = null;
      state.error = null;
    },
    resetMarkedQuestions(state) {
      state.markedQuestions.data = null;
      state.markedQuestions.error = null;
    },
  },
});

export const { resetQuestions, resetMarkedQuestions } =
  propertyQuestionnaireSlice.actions;
export default propertyQuestionnaireSlice.reducer;
