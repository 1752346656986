import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { api } from 'lib/axios';

const requestPropertyQuestionnaire = createAsyncThunk(
  'requestPropertyQuestionnaire',
  async (
    {
      token,
      listingId,
    }: { token: string; listingId: string; refetch?: boolean },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.get(`/listings/${listingId}/questions`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return rejectWithValue(error.response?.data);
      }

      return rejectWithValue(error);
    }
  }
);

const requestMarkedQuestions = createAsyncThunk(
  'requestMarkedQuestions',
  async (
    {
      token,
      listingId,
    }: { token: string; listingId: string; refetch?: boolean },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.get(
        `/listings/${listingId}/marked-questions`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return rejectWithValue(error.response?.data);
      }

      return rejectWithValue(error);
    }
  }
);

const savePropertyQuestionnaireAnswer = createAsyncThunk(
  'savePropertyQuestionnaireAnswer',
  async (
    {
      token,
      listingId,
      questionId,
      data,
    }: { token: string; listingId: string; questionId: string; data: any },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.post(
        `/listings/questions/${questionId}/answer?listing_id=${listingId}
      `,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return rejectWithValue(error.response?.data);
      }

      return rejectWithValue(error);
    }
  }
);

const requestPropertyAnswersFromSeller = createAsyncThunk(
  'requestPropertyAnswersFromSeller',
  async (
    {
      token,
      listingId,
      data,
    }: {
      token: string;
      listingId: string;
      data: {
        question_ids: string[];
        remark: string;
      };
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.post(
        `/listings/${listingId}/request-answers`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return rejectWithValue(error.response?.data);
      }

      return rejectWithValue(error);
    }
  }
);

const requestUpdateListingStatus = createAsyncThunk(
  'requestUpdateListingStatus',
  async (
    {
      token,
      listingId,
      updatedStatus,
    }: {
      token: string;
      listingId: string;
      updatedStatus: 'answered' | 'reviewed' | 'completed';
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.post(
        `/listings/${listingId}/mark-${updatedStatus}`,
        undefined,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return rejectWithValue(error.response?.data);
      }

      return rejectWithValue(error);
    }
  }
);

const requestSendEmailReminder = createAsyncThunk(
  'requestSendEmailReminder',
  async (
    {
      token,
      listingId,
    }: {
      token: string;
      listingId: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.post(
        `/listings/${listingId}/send-email-reminder`,
        undefined,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return rejectWithValue(error.response?.data);
      }

      return rejectWithValue(error);
    }
  }
);

export {
  requestPropertyQuestionnaire,
  requestMarkedQuestions,
  savePropertyQuestionnaireAnswer,
  requestPropertyAnswersFromSeller,
  requestUpdateListingStatus,
  requestSendEmailReminder,
};
