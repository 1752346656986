export const HOME_ROUTE = '/';
export const HOME_VALUATION_ROUTE = '/free-home-valuation';
export const ABA_ROUTE = '/aba';
export const SELL_ROUTE = '/sell';
export const BUY_ROUTE = '/buy';
export const QUESTIONAIRE_ROUTE = '/questionaire';
export const ASK_QUESTION_ROUTE = '/ask-question';
export const MAKE_AN_OFFER_ROUTE = '/make-an-offer';
export const DASHBOARD_ROUTE = '/dashboard';
export const SIGN_UP_ROUTE = '/auth/sign-up';
export const SIGNIN_ROUTE = '/auth/sign-in';
export const FORGOT_PASSWORD_ROUTE = '/auth/forgot-password';
export const LISTING_PAGE_ROUTE = '/listing';
export const LISTING_CONTINUE_PAGE_ROUTE = `${LISTING_PAGE_ROUTE}/continue`;
export const LISTING_WELCOME_BACK_PAGE_ROUTE = `${LISTING_PAGE_ROUTE}/welcome-back`;
export const LISTING_AGREEMENT_ROUTE = `${LISTING_PAGE_ROUTE}/agreement`;
export const LISTING_PROPERTY_TYPE_ROUTE = `${LISTING_PAGE_ROUTE}/property-type`;
export const LISTING_PROPERTY_DETAILS_ROUTE = `${LISTING_PAGE_ROUTE}/property-details`;
export const LISTING_PRICE_ROUTE = `${LISTING_PAGE_ROUTE}/price`;
// @TODO: Change below route to /assistance or something more suitable
export const LISTING_PROPERTY_ASSISTANCE_ROUTE = `${LISTING_PAGE_ROUTE}/sell-with-ease`;
export const LISTING_ADDITIONAL_DETAILS_ROUTE = `${LISTING_PAGE_ROUTE}/additional-details`;
export const LISTING_SUCCESS_ROUTE = `${LISTING_PAGE_ROUTE}/congratulations`;
