import React from 'react';
import { useNProgress } from '@tanem/react-nprogress';
import styled, { css } from 'styled-components';

export type RouteLoaderProps = {
  isRouteChanging: boolean;
};

function RouteLoader({ isRouteChanging }: RouteLoaderProps) {
  const { animationDuration, isFinished, progress } = useNProgress({
    isAnimating: isRouteChanging,
  });

  return (
    <RouteLoaderWrapper
      isFinished={isFinished}
      animationDuration={animationDuration}
      data-testid="router-wrapper"
    >
      <RouteLoaderBar
        progress={progress}
        animationDuration={animationDuration}
        data-testid="router-bar"
      >
        <div className="spinner" />
      </RouteLoaderBar>
    </RouteLoaderWrapper>
  );
}

const RouteLoaderWrapper = styled.div<{
  isFinished: boolean;
  animationDuration: number;
}>`
  ${({ isFinished, animationDuration }) => css`
    opacity: ${isFinished ? 0 : 1};
    pointer-events: none;
    transition: opacity ${animationDuration}ms linear;
  `}
`;

const RouteLoaderBar = styled.div<{
  progress: number;
  animationDuration: number;
}>`
  ${({ progress, animationDuration }) => css`
    background: #00d4a1;
    height: 2px;
    left: 0;
    margin-left: ${(-1 + progress) * 100}%;
    position: fixed;
    top: 0;
    transition: margin-left ${animationDuration}ms linear;
    width: 100%;
    z-index: 1031;
  `}
  .spinner {
    box-shadow: 0 0 10px #00d4a1, 0 0 5px #00d4a1;
    display: block;
    height: 100%;
    opacity: 1;
    position: absolute;
    right: 0;
    transform: rotate(3deg) translate(0px, -4px);
    width: 100px;
  }
`;

export default RouteLoader;
