import { forEach, isNil, startsWith } from 'lodash';
import { ParsedUrlQuery } from 'querystring';

export const numberFormat = (value: number) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  }).format(value);

export const getUTMParams = (query?: ParsedUrlQuery) => {
  const utmParams: QueryParams = {};
  const setParams = (val: QueryParamValue, key: string) => {
    if (startsWith(key, 'utm_')) {
      utmParams[key] = val as string;
    }
  };

  if (!isNil(query)) {
    forEach(query, setParams);
  } else if (typeof window !== 'undefined') {
    const url = new URL(window.location.href);
    url.searchParams.forEach(setParams);
  }

  return utmParams;
};

type QueryParamValue = string | string[] | string[][] | undefined;
type QueryParams = URLSearchParams | Record<string, string> | QueryParamValue;
