import {
  LISTING_ADDITIONAL_DETAILS_ROUTE,
  LISTING_AGREEMENT_ROUTE,
  LISTING_PAGE_ROUTE,
  LISTING_PRICE_ROUTE,
  LISTING_PROPERTY_ASSISTANCE_ROUTE,
  LISTING_PROPERTY_DETAILS_ROUTE,
  LISTING_PROPERTY_TYPE_ROUTE,
  LISTING_SUCCESS_ROUTE,
} from 'constants/routes';

export const LISTING_ID_KEY = 'richr-listing-id';

export const listingFields = [
  { name: 'address', label: 'Address', placeholder: '123 ABC Av, 2nd st' },
  { name: 'county', placeholder: 'County', label: 'County' },
  { name: 'name', placeholder: 'Adam Stern', label: 'Full Name' },
  {
    name: 'email',
    type: 'email',
    placeholder: 'abcd@example.com',
    label: 'Email',
  },
  { name: 'phone_number', label: 'Phone Number' },
];

export const listingRadioButtons = [
  {
    label: 'Yes',
    id: 'listing-radio-yes',
    value: 'true',
  },
  {
    label: 'No',
    id: 'listing-radio-no',
    value: 'false',
  },
];

export const media = {
  rightArrow: '/static/images/home/right-arrow.svg',
};

export const LISTING_UPDATE_PAGES_SEQUENCE = [
  LISTING_PAGE_ROUTE,
  LISTING_PROPERTY_TYPE_ROUTE,
  LISTING_PROPERTY_DETAILS_ROUTE,
  LISTING_PRICE_ROUTE,
  LISTING_AGREEMENT_ROUTE,
  LISTING_PROPERTY_ASSISTANCE_ROUTE,
  LISTING_ADDITIONAL_DETAILS_ROUTE,
  LISTING_SUCCESS_ROUTE,
];
