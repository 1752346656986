import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { HomesState, IHome } from './types';

const initialState: HomesState = {
  data: [],
};

/* eslint-disable no-param-reassign */
export const homesSlice = createSlice({
  name: 'homesSlice',
  initialState,
  reducers: {
    fillHomes(state, { payload }: PayloadAction<IHome[]>) {
      state.data = payload;
    },
  },
});

export const { fillHomes } = homesSlice.actions;
