/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { zohoHomeValuationThunk } from './thunks';
import { IAsyncFlowInitialState } from './types';

const asyncFlowInitialState: IAsyncFlowInitialState = {
  loading: false,
  data: null,
  error: null,
};

const initialState = {
  zohoHomeValuation: asyncFlowInitialState,
};

export const homeValuationSlice = createSlice({
  name: 'homeValuationSlice',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(zohoHomeValuationThunk.pending, ({ zohoHomeValuation }) => {
      zohoHomeValuation.loading = true;
      zohoHomeValuation.error = null;
    });
    builder.addCase(
      zohoHomeValuationThunk.fulfilled,
      ({ zohoHomeValuation }, action) => {
        zohoHomeValuation.loading = false;
        zohoHomeValuation.data = action.payload;
      }
    );
    builder.addCase(
      zohoHomeValuationThunk.rejected,
      ({ zohoHomeValuation }, action) => {
        zohoHomeValuation.loading = false;
        zohoHomeValuation.data = null;
        zohoHomeValuation.error = action.payload;
      }
    );
  },
  reducers: {},
});

export default homeValuationSlice.reducer;
