/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { requestCallbackThunk } from './thunks';
import { IAsyncFlowInitialState } from './types';

const asyncFlowInitialState: IAsyncFlowInitialState = {
  loading: false,
  data: null,
  error: null,
};

const initialState = {
  ...asyncFlowInitialState,
  requestCallback: asyncFlowInitialState,
};

export const freeHomeValuationSlice = createSlice({
  name: 'freeHomeValuation',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(requestCallbackThunk.pending, ({ requestCallback }) => {
      requestCallback.loading = true;
      requestCallback.error = null;
    });
    builder.addCase(
      requestCallbackThunk.fulfilled,
      ({ requestCallback }, action) => {
        requestCallback.loading = false;
        requestCallback.data = action.payload;
        requestCallback.error = null;
      }
    );
    builder.addCase(
      requestCallbackThunk.rejected,
      ({ requestCallback }, action) => {
        requestCallback.loading = false;
        requestCallback.data = null;
        requestCallback.error = action.payload;
      }
    );
  },
  reducers: {},
});

export default freeHomeValuationSlice.reducer;
