import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { HeadersDefaults } from 'axios';

import { AuthState, IJwt, IUser } from './types';
import { api } from 'lib/axios';

interface CommonHeaderProperties extends HeadersDefaults {
  Authorization: string;
}

const initialState: AuthState = {
  user: {
    data: null,
    jwt: null,
  },
};

/* eslint-disable no-param-reassign */
export const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    addUser(state, { payload }: PayloadAction<{ data: IUser; jwt: IJwt }>) {
      state.user = {
        data: payload.data,
        jwt: payload.jwt,
      };
      api.defaults.headers = {
        ...api.defaults.headers,
        Authorization: payload.jwt.access,
      } as CommonHeaderProperties;
      Cookies.set('jwt:richr', payload.jwt.access);
    },
    removeUser(state) {
      state.user = {
        data: null,
        jwt: null,
      };
      api.defaults.headers = {
        ...api.defaults.headers,
        Authorization: '',
      } as CommonHeaderProperties;
      Cookies.remove('jwt:richr');
    },
  },
});

export const { addUser, removeUser } = authSlice.actions;
