import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { api } from 'lib/axios';
import { CallbackRequest } from './types';

const requestCallbackThunk = createAsyncThunk(
  'requestCallback',
  async (data: CallbackRequest, { rejectWithValue }) => {
    try {
      const { leadId } = data;

      const response = await api.post(`/leads/${leadId}/request-callback`);

      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return rejectWithValue(error.response?.data);
      }

      return rejectWithValue(error);
    }
  }
);

export { requestCallbackThunk };
