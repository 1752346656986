import { createSlice } from '@reduxjs/toolkit';

import { GlobalState } from './types';

const initialState: GlobalState = {
  isOpenMobileNav: false,
  isVerifiedBox: false,
};

/* eslint-disable no-param-reassign */
export const globalSlice = createSlice({
  name: 'globalSlice',
  initialState,
  reducers: {
    toggleMobileNav(state) {
      state.isOpenMobileNav = !state.isOpenMobileNav;
    },
    toggleIsVerifiedBox(state) {
      state.isVerifiedBox = !state.isVerifiedBox;
    },
  },
});

export const { toggleMobileNav, toggleIsVerifiedBox } = globalSlice.actions;
