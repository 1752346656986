import { createGlobalStyle } from 'styled-components';

import { device } from './breakpoints';

export const GlobalStyles = createGlobalStyle`
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}
html{
 
}
body {
  background-color: #fff;
  font-family: ${({ theme }) => theme.typography.family};
  overflow-x: hidden;

  &:has(.is-swiper-hovered) {
    overscroll-behavior-x: none;
  }

  &:has(.disable-body-scroll) {
    overflow: hidden;
  }

  &:has(.hide-chatbot) {
    #front-chat-iframe {
      display: none !important;
    }
  }

  &:has(.is-sticky-navbar) {
    .header-nav {
      position: sticky;
      background-color: ${({ theme }) => theme.colors.white};
      z-index: 3;
    }
  }

  &::-webkit-scrollbar {
    width: 0.3vw;
    background-color: none;
  }

  &::-webkit-scrollbar-track {
    background: #c4c4c4;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #064ea3;
    border-radius: 4px;
  } 
}

button {
  border: none;
}
button[type="button"]{
  cursor: pointer;
}
a {
  text-decoration: none;
}
ul {
  list-style-type: none;
}
input {
  outline: none;
}
img{
  user-select: none;
  pointer-events: none;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
}

// Loader //

  .lds-roller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
  }
  .lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #00d4a1;
    margin: -4px 0 0 -4px;
  }
  .lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
  }
  .lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
  }
  .lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
  }
  .lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
  }
  .lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
  }
  .lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
  }
  .lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
  }
  .lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
  }
  .lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
  }
  .lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
  }
  .lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
  }
  .lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
  }
  .lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
  }
  .lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
  }
  .lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
  }
  .lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
  }
  @keyframes lds-roller {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .tippy-content {
    font-size: 14px;
    line-height: 1.35;
    font-weight: ${({ theme }) => theme.typography.fontWeights.semibold};
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.green};
    z-index: 9999;

    @media ${device.mobile} {
      font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
    }
  }

  .tippy-arrow::before {
    color: ${({ theme }) => theme.colors.green};
  }
  

`;
